<template>
	<v-container>
		<v-form
			style="max-width: 600px; margin: auto"
			class="pa-2"
			@submit.prevent="login"
			ref="login"
		>
			<v-img
				class="logo mb-3"
				contain
				:src="`/img/logo_${$store.getters.getNombreEmpresa}.png`"
				max-height="300"
				alt="logo"
			/>
			<v-card>
				<v-sheet color="primary">
					<v-card-title class="white--text">{{portal}}</v-card-title>
				</v-sheet>
				<div class="pa-4 pt-0">
					<v-alert class="mt-2" v-model="error" type="error"
						>Los datos de acceso no son correctos</v-alert
					>

					<v-text-field
						v-model="usuario"
						filled
						label="usuario"
						name="username"
						type="text"
						dense
						hide-details="auto"
						class="my-4"
						:rules="[rules.req]"
					></v-text-field>
					<v-text-field
						v-model="pass"
						filled
						label="contraseña"
						name="password"
						type="password"
						dense
						hide-details="auto"
						class="my-4"
						:rules="[rules.req]"
					></v-text-field>
					<v-btn type="submit" block color="primary" :loading="loading"
						>Entrar</v-btn
					>
				</div>
			</v-card>
		</v-form>

		<v-dialog :value="!!msg" max-width="600" persistent>
			<v-card class="pa-4">
				<v-card-title v-text="msg" />
				<v-card-actions>
					<v-spacer />
					<v-btn color="primary" @click="msg = false">Aceptar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>


export default {
	data() {
		return {
			portal: ((s = window.location.host.replaceAll(/www./g, "").split(".")[0]) => s && s[0] && s[0].toUpperCase() + s.slice(1))(),
			usuario: null,
			pass: null,
			error: false,
			empresa: null,
			loading: false,
			modalEmpresa: false,
			msg: this.$route.query.msg,
			development: process.env.NODE_ENV == "development",
			empresas: [],
			rules: {
				req: (v) => !!v || "Este campo es obligatorio",
			},
		};
	},
	methods: {
		login() {
			if (!this.$refs["login"].validate()) return;
			this.loading = true;
			this.$store
				.dispatch("login", {
					user: this.usuario,
					password: this.pass,
				})
				.then(() => {
					this.$router.push(this.$route.query.redirect || "/");
					this.loading = false;
				})
				.catch(() => {
					this.error = true;
					this.loading = false;
					this.pass = "";
				});
		},
	},
};
</script>

<style>
.logo {
	max-width: 450px;
	margin: auto;
}
</style>
